import { ethers } from "ethers";
import VestedAbi from "./VestedAbi.json";
import abi from "./MainContractAbi.json";

import {
  Box,
  Flex,
  Text,
  Image,
  Container,
  Heading,
  Button,
} from "@chakra-ui/react";
import ColLogo from "./assets/mainContent/ColLogo.png";
import "./App.css";
import NavBarMint from "./NavBarMint.jsx";
import SwordsIcon from "./lib/swords";
import React, { useState, useEffect } from 'react';
const fs = require("fs");

const MainAddress = "0x517C3f41b49fa5C167d7eaC8D518C25eE2846be8";

const MintPage = ({ accounts, setAccounts }) => {
  const isConnected = Boolean(accounts[0]);
  const [offline, setOffline] = useState(false);
  const [stakingDuration, setStakingDuration] = useState('1'); // Default staking duration is 1 month

  async function connectAccount() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccounts(accounts);
    }
  }

  async function handleMintNormal() {
    await HandleNetworkSwitch();


    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const contract = new ethers.Contract(
        MainAddress,
        abi,
        provider.getSigner()
      );
      // Check the signer's balance
      const balance = await provider.getSigner().getBalance();

      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      console.log("Address:", await signer.getAddress());


      try {
        let response;
        if (stakingDuration == '1') {
          response = await contract.mint(stakingDuration, { value: ethers.utils.parseEther("0.05") });
        }
        if (stakingDuration == '2') {
          response = await contract.mint(stakingDuration, { value: ethers.utils.parseEther("0.15") });
        }
        if (stakingDuration == '3') {
          response = await contract.mint(stakingDuration, { value: ethers.utils.parseEther("0.35") });
        }
        if (stakingDuration == '4') {
          response = await contract.mint(stakingDuration, { value: ethers.utils.parseEther("0.35") });
        }
        // create the notification element
        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#137DC5"; // Set the background color to blue
        notification.style.color = "black";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        // set the notification text
        notification.innerText = "SUCCESS!";

        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);
        await response.wait();

        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#137DC5"; // Set the background color to blue
        notification.style.color = "black";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        // set the notification text
        notification.innerText = "Your Pass NFT has been minted!";
        setOffline(true);

        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);

      } catch (err) {
        console.log("error: ", err);


        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#137DC5"; // Set the background color to blue
        notification.style.color = "black";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        // set the notification text
        if (err.message) {
          notification.innerText = "FAIL!";
        } else {
          notification.innerText = "FAIL!";
        }

        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);
      }
    }
  }

  return (
    <>
      <NavBarMint accounts={accounts} setAccounts={setAccounts} />
      <Container
        as="main"
        maxW={"1700px"}
        centerContent
        mx={"auto"}
        mt={{ base: "1rem", lg: "6rem" }}
      >
        <Image
          src={ColLogo}
          align="center"
          boxSize={{ base: "300px", md: "400px" }}
        />
        <Flex justify="space-between" gap={"0.55rem"}
        >
          <Box
            color={"#137DC5"}
            className="font-cinzel"
            bgColor={"#FFFFFF"}
            px={{ base: "1.5rem", md: "2.5rem" }}
            py={{ base: "0.75rem", md: "1rem" }}
            rounded={"1rem"}
            my={"1.5rem"}
            border={"1px solid #137DC5"}
          >
            <Heading
              fontSize={{ md: "2.3rem" }}
              className="font-cinzel"
              my={"0.25rem"}
            >
              Pass Sale
            </Heading>
            <Text
              fontWeight={"bold"}
              my={0}
              fontSize={{ base: "1.5rem", md: "2rem" }}
              lineHeight={"2rem"}

            >
              Select Your Pass Type
            </Text>

            <div style={{ margin: '1rem 0' }}>
              <label htmlFor="staking-duration" className="font-cinzel" style={{ fontSize: '1.5rem' }}>
              </label>
              <select
                id="staking-duration"
                value={stakingDuration}
                onChange={(e) => setStakingDuration(e.target.value)}
                className="font-cinzel"

                style={{
                  fontSize: '1.5rem', // Increase the font size
                  backgroundColor: "#FFFFFF", // Set the background color to white
                  height: '3rem', // Adjust the height of the select element
                  outline: 'none' // Remove the outline

                }}
              >
                <option value="1">Bronze Pass - 0.05 ETH</option>
                <option value="2">Silver Pass - 0.15 ETH</option>
                <option value="3">Gold Pass - 0.35 ETH</option>
                <option value="4">Diamond Pass - 1 ETH</option>
              </select>
            </div>
            {isConnected ? (

              <Button
                type="button"
                className="font-cinzel"
                style={{ backgroundColor: "#137DC5", color: "#FFFFFF", marginTop: "0.5rem" }}
                onClick={handleMintNormal}
                fontSize={{ base: "1.5rem", md: "2rem" }}
                fontWeight={"bold"}
                px={"1.5rem"}
                py={"0.25rem"}
                rounded={"1rem"}
                gap={"0.25rem"}
                disabled={offline}
              >
                Mint
              </Button>

            ) : (
              <Button
                type="button"
                className="font-cinzel"
                style={{ backgroundColor: "#137DC5", color: "#FFFFFF", marginTop: "0.5rem" }}
                onClick={handleMintNormal}
                fontSize={{ base: "1.5rem", md: "2rem" }}
                fontWeight={"bold"}
                px={"1.5rem"}
                py={"0.25rem"}
                rounded={"1rem"}
                gap={"0.25rem"}
                disabled={!isConnected}
              >
                Mint
              </Button>
            )}
          </Box>
        </Flex>

        <Flex justify="center" align="top">
          {isConnected ? (
            <Text
              align=""
              fontSize="1.75rem"
              className="ConnectToAdjust"
              fontFamily="Cinzel Decorative"
              color="white"
            >
            </Text>
          ) : (
            <Text
              align=""
              fontSize="1.75rem"
              className="ConnectToAdjust"
              fontFamily="Cinzel Decorative"
              color="white"
            >
              Connect To Proceed
            </Text>
          )}
        </Flex>
      </Container>
    </>
  );
};
export default MintPage;
async function HandleNetworkSwitch() {

  try {
    if (!window.ethereum) throw new Error("No crypto wallet found");

    if (window.ethereum.networkVersion !== 1) {

      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: "0x1" }]
        });

      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                ...networks['homestead']
              }]
          });
        }
      }
    }
  } catch (err) {
    console.log(err.message);
  }
}

const networks = {
  homestead: {
    chainId: `0x${Number(1).toString(16)}`,
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["https://api.mycryptoapi.com/eth/"],
    blockExplorerUrls: ["https://etherscan.io/"]
  }
};
/**
 * 
 * 
 *        <Image 
        src="path/to/allowlisted-image.jpg"
        isHidden={!isAllowlisted(accounts[0])}
      />
MAINNET: 
async function HandleNetworkSwitch() {

    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");

        if (window.ethereum.networkVersion !== 1) {

            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x1" }]
                });

            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                ...networks['homestead']
                            }]
                    });
                }
            }
        }
    } catch (err) {
        console.log(err.message);
    }
}

const networks = {
    homestead: {
        chainId: `0x${Number(1).toString(16)}`,
        chainName: "Ethereum Mainnet",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://api.mycryptoapi.com/eth/"],
        blockExplorerUrls: ["https://etherscan.io/"]
    }
};
SEPOLIA:
async function HandleNetworkSwitch() {
    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");

        if (window.ethereum.networkVersion !== "11155111") {

            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0xAB13" }]
                });
            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                ...networks['sepolia']
                            }]
                    });
                }
            }
        }
    } catch (err) {
        console.log(err.message);
    }
}

const networks = {
    sepolia: {
        chainId: `0x${Number(11155111).toString(16)}`,
        chainName: "Sepolia",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://sepolia.infura.io/v3/"],
        blockExplorerUrls: ["https://sepolia.etherscan.io"]
    }
};


GÖRLI:
async function HandleNetworkSwitch() {
    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");

        if (window.ethereum.networkVersion !== 5) {

            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x5" }]
                });
            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                ...networks['goerli']
                            }]
                    });
                }
            }
        }
    } catch (err) {
        console.log(err.message);
    }
}

const networks = {
    goerli: {
        chainId: `0x${Number(5).toString(16)}`,
        chainName: "Goerli",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://goerli.infura.io/v3/YOUR-PROJECT-ID"],
        blockExplorerUrls: ["https://goerli.etherscan.io/"]
    }
};

 */