import { useState } from "react";
import "./App.css";
import MintPage from "./MintPage.jsx";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  const [accounts, setAccounts] = useState([]);

  return (
    <ChakraProvider>
      <Router>
        <div className="container">
          <div className="App">
            <Routes>
              <Route
                path="/"
                element={
                  <MintPage accounts={accounts} setAccounts={setAccounts} />
                }
              />
            </Routes>
          </div>
          <div className="moving-background "></div>
        </div>
      </Router>
    </ChakraProvider>
  );
}

export default App;

/*
          <Switch>

          <Route path='/' exact render={() => <MainMint accounts={accounts} setAccounts={setAccounts} />} />
          </Switch>
  return (
    <Router>
      <div className="container background-container">W
        <div className="App">
          <NavBar accounts={accounts} setAccounts={setAccounts} />
          <Switch>
            <Route exact path="/">
              <MainMint accounts={accounts} setAccounts={setAccounts} />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Redirect to="/" />
          </Switch>
        </div>
        <div className = "moving-background "></div>
      </div>
    </Router>
  );
}

export default App;
*/
